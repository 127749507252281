.form{
    margin-top: 50px;
    padding:10px;
    margin-bottom: 10px;
    margin-right:70px;
    background-color: white;
    color: black;
    display: flex;
    align-items: center;
    justify-content: center;
  
    span {
      vertical-align: middle;
      flex: 0 0 100px;
    }
    .left {
        width: 100px;
        flex: 0 0 100px;
    }
    .center {
        border-left: 1px solid #eee;
        flex-grow: 1;
    }
    
    input{
      font-size: 1em;
      color:black;
      background-color: white;
      font-family: 'Special Elite';
      width: 100%;
    }
    
    button{
      font-size: 1em;
      color:black;
      background-color: white;
      margin-left:20px;
      margin-right:10px;
      width: 100px;
      flex: 0 0 100px;
    }
  }