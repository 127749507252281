@mixin vertical-align($position){
  position: $position;
  top:50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

@mixin all-transition($time) {
  transition: all $time;
  -webkit-transition: all $time;
}



.menu{
  background-color: white;
  border-left: solid 1px black;
  color: #333333;
	width: 150px;
  height:100%;
  padding-left: 50px;
  position: fixed;
  right:-130px;
  @include all-transition(0.3s);
  z-index: 100;

  &.opened{
    transform: translate3d(-130px, 0, 0);
    animation-timing-function: 1s ease-in;
  }

  .title{
    @include vertical-align(absolute);
    transform: rotate(270deg);
    left:-20px;
    text-decoration: none;
    color: inherit;
    padding:20px;
  }

  .nav{
    @include vertical-align(absolute);

    li{
      padding-bottom: 30px;
      list-style-type: none;

      a{
        display: block;
        text-decoration: none;
        color: inherit;

        &:hover{
          color: black;
        }
      }


    }
  }
}