html{
  height: 100%;
}
body{
  background-color: #222222;
  color:white;
  font-size: 1.2em;
  font-family: 'Special Elite';
  margin:0;
  height: 100%;
}
#root{
  display: flex;
  flex-direction: column;
  height:100%;
}
.mainWrapper {
  flex: 1 0 auto;
}

.App{
  padding-left: 10px;
  padding-right: 10px;
}

.hidden{
  display: none;
}

.Typist .Cursor {
  display: inline-block;

  &--blinking {
    opacity: 1;
    animation: blink 1s linear infinite;
    @keyframes blink {
      0% { opacity:1; }
      50% { opacity:0; }
      100% { opacity:1; }
    }
  }
}

.console{
  .Cursor{
    display:none;
  }

  .output{
    margin:10px;
    line-height: 1.3em;
    margin-right:70px;

    a {
      color: White;
      font-weight: bold;
    }
  }

  .active-output .Cursor{
    display: inline-block;
  }
}
